<template>
  <el-dialog
    :visible.sync="dialogVisible"
    width="30%"
    :show-close="false"
    append-to-body
    modal-append-to-body
    @close="handleClose"
  >
    <div class="dialog-modal-box">
      <div class="vip-tool-box-header title">
        <h2>{{ phonenumber ? "修改手机号" : "绑定手机号" }}</h2>
        <i class="ri-close-large-line" @click="handleClose"></i>
      </div>
      <el-form ref="phoneForm" :model="form">
        <el-form-item prop="phone">
          <el-input v-model="form.phone" autocomplete="off" placeholder="请输入手机号码"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="bindPhone">{{ phonenumber ? "修改" : "绑定" }}</el-button>
      </div>
      <img class="background" src="@/assets/img/logo.svg" alt="" />
    </div>
  </el-dialog>
</template>

<script>
import { mapState } from "vuex";
import store from "@/store";
export default {
  data() {
    return {
      dialogVisible: false,
      form: {
        phone: "",
      },
    };
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo || {},
    }),
    phonenumber() {
      return this.userInfo.phonenumber;
    },
  },
  mounted() {
    this.form.phone = this.phonenumber || "";
  },
  methods: {
    openDialog() {
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
    sendCode() {
      // 发送验证码逻辑
      console.log("发送验证码");
    },
    getUserInfo() {
      this.commonApiService.getUserInfo().then((res) => {
        if (res.code == 200) {
          store.commit("setUserInfo", res.data);
        }
      });
    },
    bindPhone() {
      // 绑定手机号逻辑
      const params = { phonenumber: this.form.phone };
      this.commonApiService.userInfoUpdate(params).then((res) => {
        if (res.code == 200) {
          this.showSuccessMsg("修改成功");
          this.getUserInfo();
          this.dialogVisible = false;
        }
        this.showUpdate = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep {
  .el-dialog {
    min-width: 300px !important;
    border-radius: 8px !important;
    background-image: linear-gradient(180deg, #dae6fe 0%, #fff 100%) !important;
  }

  .el-dialog__header {
    padding: 0 !important;
  }

  .el-dialog__body {
    padding: 0 !important;
  }

  .el-dialog__footer {
    text-align: center !important;
  }
}
.dialog-modal-box {
  padding: 24px;
  z-index: 0;
  height: 100%;
  justify-content: center;
  align-items: center;
  overflow-y: auto;
  position: relative;

  .title {
    margin-bottom: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    height: 10%;
  }

  .vip-tool-box-header {
    h2 {
      font-size: 16px;
      font-weight: 600;
      color: #303133;
      margin-bottom: 16px;
    }
  }
  .background {
    z-index: -1;
    position: absolute;
    width: 150px;
    height: 150px;
    top: 10px;
    right: 10px;
    filter: blur(3px);
  }

  .dialog-footer {
    text-align: center;
  }
}
</style>
